class ajain {

    /**
     * Prepares the class for execution, and defines the fundamental .busy promise flag.
     */
    static prepare() {
        if(this.busy === undefined) {
            this.busy = new Promise((resolve) => {
                let page = $(".page");
                let label_ = page.attr("id").split("page-")[1];
                let label = label_.replace(/_/g,"/");
                let requestedLabel_ = $("body").attr("data-landing-page");
                let requestedLabel = requestedLabel_.replace(/_/g,"/");

                if(label !== requestedLabel) this.alertPageNotFound(requestedLabel);

                this.pages = {};
                this.pages[label] = page;
                this.activePage = label;

                this.afterLoadPage(label);
                this.afterSetPage(label);

                this.pageBox = $(".page-box");

                if(label === "welcome") window.history.replaceState({ page: label }, "", "/");
                else {
                    window.history.replaceState({ page: label }, "", "/" + label + "/");
                }

                window.onpopstate = function (e) {
                    if(e.state && e.state.page) {
                        console.log(e.state.page);
                        this.setPage(e.state.page);
                    }
                }.bind(this);

                jQuery.getJSON("https://ajainphysics.com/lib/menu.json", function(data) {
                    this.menu = data;
                    resolve();
                }.bind(this));
            });
        }
    }

    /**
     * Error handler for .busy rejects.
     */
    static busyErrorHandler() {
        this.busy = new Promise((resolve, reject) => {
            this.busy.then(resolve).catch(function(e) {
                switch(e.error) {
                    case "page_not_found":
                        console.log(e.message);
                        resolve();
                        break;
                    default:
                        reject(e)
                }
            }.bind(this));
        });
    }

    /**
     * Loads a page from the server.
     * @param label - Label of the page, e.g. research/hydrodynamics
     */
    static loadPage(label = "welcome") {
        let label_ = label.replace(/\//g,"_");
        this.busy = new Promise((resolve, reject) => {
            this.busy.then(function() {
                $.get("/ajax/loadpage.php?p=" + label, function(data) {
                    if(data) {
                        this.pageBox.prepend(data);
                        this.pages[label] = $("#page-" + label_).hide();
                        this.afterLoadPage(label);
                        resolve();
                    } else {
                        reject({
                            error: "page_not_found",
                            page: label,
                            message: "Page '" + label + "' was not found!"
                        });
                    }
                }.bind(this));
            }.bind(this)).catch(reject);
        });
        this.busyErrorHandler();
    }

    static setPage(label = "welcome") {
        if(this.activePage === label) return; // If the page is already active, do nothing.
        if(!this.pages[label]) this.loadPage(label); // If the page is not loaded, load it.

        this.busy = new Promise((resolve, reject) => {
            this.busy.then(function() {
                if(!this.pages[label]) {
                    this.alertPageNotFound(label);
                    label = "welcome";
                } else {
                    this.alertPageNotFound();
                }
                this.pages[this.activePage].fadeOut(function() {
                    this.pages[label].fadeIn();
                    this.activePage = label;
                    this.afterSetPage(label);
                    resolve();
                }.bind(this));
            }.bind(this)).catch(reject);
        });
        this.busyErrorHandler();
    }

    static afterLoadPage(label) {
        let nthis = this;
        $(".pagelink")
            .off("click")
            .click(function(e) {
                e.preventDefault();
                let href = $(this).attr("href");
                let hrefArray = href.split("ajainphysics.com/");
                let linklabel = hrefArray[hrefArray.length - 1];
                if(!linklabel) linklabel = "welcome";

                nthis.setPage(linklabel);

                let baseTitle = document.title.split("|");
                baseTitle = baseTitle[baseTitle.length - 1].trim();

                if(linklabel === "welcome") {
                    window.history.pushState({ page: linklabel }, baseTitle, "/");
                    document.title = baseTitle;
                }
                else {
                    let parentLabel = linklabel.split("/");
                    let parentName = [],
                        level = null;
                    for(let i=0; i<parentLabel.length; i++) {
                        if(!level) level = nthis.menu[parentLabel[i]];
                        else level = level.children[parentLabel[i]];
                        parentName.push(level.name)
                    }

                    let newTitle = parentName.reverse().join(" \u00ab ") + " | " + baseTitle;

                    window.history.pushState({ page: linklabel }, newTitle, "/" + linklabel + "/");
                    document.title = newTitle;
                }
            });

        $(".mySpires-list:not(.loaded)").each(function(index, list) {
            this.mySpiresList($(list));
        }.bind(this));
        
        RefSpires.load();
        
        $(".card-read-more.collapsed")
            .off("click")
            .click(function() {
                $(this).removeClass("collapsed", 1000);
            });

        if(label === "research") {
            $(".career li").click(function (e) {
                e.preventDefault();
                if (!$(this).hasClass("active")) {
                    let $all = $(".career li");

                    $all.each(function () {
                        if ($(this).hasClass("active")) {
                            $(this).find("> div > div").slideUp(function () {
                                $(this).removeClass("active");
                            }.bind(this));
                        }
                    });

                    $(this).find("> div > div").slideDown(function () {
                        $(this).addClass("active");
                    }.bind(this));
                }
            });
        }
    }
    
    static afterSetPage(label) {
        // Collapse the navbar and scroll to top
        if($(".topbar .navbar-collapse").hasClass("show")) $(".topbar .navbar-toggler").click();
        $("html, body").animate({ scrollTop: 0 }, "fast");

        // Set the body class for custom CSS
        $("body").attr("class", label + "-body");

        // Update the navigation links
        $(".pagelink-nav").removeClass("active");

        let labelRoot = label.split("/")[0];
        $(".pagelink-nav-" + labelRoot).addClass("active");
    }

    static mySpiresList($list) {
        let q = $list.attr("data-q");
        let field = $list.attr("data-field");
        if(!field) field = "arxiv";

        // /ajax/query_myspires.php

        console.log(1);

        $.ajax({
            type: "POST",
            url: "https://myspires.ajainphysics.com/api/",
            data: {q: q, field: field},
            success: function (records) {
                if(!records) {
                    reject("mySpires servers did not reply!");
                    return;
                }

                let citeArray = [];
                for(let key of Object.keys(records)) {
                    let record = records[key];
                    citeArray.push(record.author.replace("Akash Jain", "<strong>Akash Jain</strong>") +
                        ", <em>" + record.title + ",</em> " +
                        "[<a href='https://arxiv.org/abs/" + record.arxiv + "'>arXiv:" + record.arxiv + "</a>]");
                }

                $list.html(citeArray.map(function(entry) {
                    return "<li>" + entry + ".</li>";
                }).join(""));
                $list.addClass("loaded");

            }.bind(this),
            dataType: "json",
            error: function() {
                console.log({
                    mySpires: "API did not reply!",
                    args: {q: q, field: field}
                });
            }
        });
    }

    static alertPageNotFound(label) {
        let $alert = $("#alert-page-not-found");
        if(label) {
            $alert.find("code").html(label);
            $alert.fadeIn();
        } else {
            $alert.fadeOut();
        }
    }
}

$(function() {
    "use strict";
    ajain.prepare();
});